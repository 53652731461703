import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ImageModal from './ImageModal';
import './App.css';

const ImageViewer = () => {
  const [allItems, setAllItems] = useState([]);
  const [displayedItem, setDisplayedItem] = useState(null); // Used for search results
  const [items, setItems] = useState([]); // To store displayed images for infinite scroll
  const [searchQuery, setSearchQuery] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [validationError, setValidationError] = useState('');

  useEffect(() => {
    window.addEventListener('resize', fetchInitialImages);
    fetchInitialImages();
    return () => window.removeEventListener('resize', fetchInitialImages);
  }, []);

  const fetchInitialImages = async () => {
    const fetchedItems = Array.from({ length: 6666 }, (_, i) => ({
      id: i + 1,
      imgSrc: `PNG/${String(i + 1).padStart(4, '0')}.png`
    }));
    setAllItems(fetchedItems);
    // Initially set items for infinite scroll but don't overwrite if a search is displayed
    if (!searchQuery) {
      setItems(fetchedItems.slice(0, calculateTotalImages()));
    }
  };

  const calculateTotalImages = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const imagesPerRow = Math.floor(width / 75);
    const rowsNeeded = Math.ceil(height / 75);
    return imagesPerRow * rowsNeeded;
  };

  const fetchMoreImages = (numImages = 50) => {
    // Extend the allItems array only if not searching
    if (!searchQuery && items.length < allItems.length) {
      setItems(prev => [...prev, ...allItems.slice(prev.length, prev.length + numImages)]);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      const id = parseInt(searchQuery, 10);
      if (id && id >= 1 && id <= 6666) {
        setValidationError('');
        const searchedItem = {
          id,
          imgSrc: `PNG/${String(id).padStart(4, '0')}.png`
        };
        setDisplayedItem(searchedItem); // Directly display searched item
      } else {
        setDisplayedItem(null); // Clear search result if invalid
        setValidationError('Enter an ID between 1 and 6666.');
      }
    } else {
      setDisplayedItem(null); // Clear search result if search query is cleared
      setValidationError('');
    }
  }, [searchQuery]);

  const handleNext = () => {
    setSelectedItem((prev) => {
      const currentIndex = allItems.findIndex(item => item.id === prev.id);
      return allItems[(currentIndex + 1) % allItems.length];
    });
  };

  const handlePrevious = () => {
    setSelectedItem((prev) => {
      const currentIndex = allItems.findIndex(item => item.id === prev.id);
      return allItems[(currentIndex - 1 + allItems.length) % allItems.length];
    });
  };

  return (
    <>
      <a href="https://magiceden.io/ordinals/marketplace/glowies" target="_blank" rel="noopener noreferrer"><img src="logo.svg" alt="Glowies Logo" className="my-svg" /></a>
      {/* Headline for the operation with white color */}
      <h2 style={{ textAlign: 'center', marginTop: '8px', color: 'white' }}>GLOW-SEC // XBT // ORDOP</h2>
      <div style={{ color: 'white', textAlign: 'center', marginBottom: '20px' }}>
        <a href="https://www.ord.io/66158006" target="_blank" rel="noopener noreferrer">
          Manifest
        </a>
        {" // "}
        <a href="https://magiceden.io/ordinals/marketplace/glowies" target="_blank" rel="noopener noreferrer">
          MagicEden
        </a>
        {" // "}
         <a href="https://twitter.com/glowiesbtc" target="_blank" rel="noopener noreferrer">
          ConspiraPups
        </a>
      </div>

      <div style={{ margin: '10px 0' }}>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Enter Agent ID"
          style={{ padding: '0.5em 1em', maxWidth: '75%' }}
        />
        {validationError && <p style={{ color: 'red' }}>{validationError}</p>}
      </div>
      {displayedItem ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '8px' // Add padding around the container
        }}>
          <img 
            src={displayedItem.imgSrc} 
            alt={`Item ${displayedItem.id}`} 
            className="image-item" 
            onClick={() => setSelectedItem(displayedItem)} 
            style={{ 
              maxWidth: 'calc(80% - 20px)', // Adjust for padding to maintain 80% max width of the parent
              maxHeight: 'calc(80vh - 20px)', // Adjust for padding to maintain 80vh max height of the parent
              objectFit: 'contain' 
            }} 
          />
        </div>
      ) : (
        <InfiniteScroll
          dataLength={items.length}
          next={() => fetchMoreImages(50)}
          hasMore={hasMore && items.length < 6666}
          loader={<h4>Loading...</h4>}
          endMessage={<p style={{ textAlign: 'center' }}><b></b></p>}
          className="image-container"
        >
          {items.map((item) => (
            <img key={item.id} src={item.imgSrc} alt={`Item ${item.id}`} className="image-item" onClick={() => setSelectedItem(item)} />
          ))}
        </InfiniteScroll>
      )}
      {selectedItem && <ImageModal item={selectedItem} onClose={() => setSelectedItem(null)} onNext={handleNext} onPrevious={handlePrevious} />}
    </>
  );
};

export default ImageViewer;
