import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Properly bind your modal to the app element

const ImageModal = ({ item, onClose, onNext, onPrevious }) => {
  const [metadata, setMetadata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [contentID, setContentID] = useState(null);
  const [idError, setIdError] = useState('');

  useEffect(() => {
    setLoading(true);
    fetch(`JSON/${String(item.id).padStart(4, '0')}.json`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log("Metadata fetched: ", data); // Log fetched metadata
        setMetadata(data);
        setLoading(false);
      })
      .catch(error => {
        console.error("Fetch error: ", error);
        setError(error.toString());
        setLoading(false);
      });

    fetch(`ID/${String(item.id).padStart(4, '0')}.json`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log("Content ID fetched: ", data); // Log fetched content ID
        setContentID(data.id); // Update to use the "id" field from the JSON
      })
      .catch(error => {
        console.error("Fetch error: ", error);
        setIdError(error.toString());
      });
  }, [item.id]);

  const highResImgSrc = item.imgSrc.replace('PNG', 'PNG_1200x1200_BLACK');

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.85)',
      zIndex: 1000,
    },
    content: {
      color: 'white',
      background: 'black',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      height: '100%',
      overflow: 'auto',
      border: 'none',
      padding: '1em',
      borderRadius: '0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }
  };

  const renderBadges = () => {
    if (!metadata) return null;

    const badges = Object.entries(metadata).map(([key, value], index) => {
      if (key === "Title" || key === "Description" || key === "Author") return null; // Skip "Title", "Author", "Description", "trait count", "surveillance" here
      return (
        <React.Fragment key={index}>
          <span className="metadata-badge" style={{ display: 'inline-block', margin: '0.5em', padding: '1em', background: '#21a92b', borderRadius: '5px', fontSize: '0.5em' }}>
            {`${key}: ${value}`}
          </span>
          {(index + 1) % 5 === 0 && <span className="line-break"></span>}
        </React.Fragment>
      );
    });

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
        {badges}
      </div>
    );
  };

  return (
    <Modal 
      isOpen 
      onRequestClose={onClose} 
      style={customStyles}
      contentLabel="Image Details"
    >
      <div style={{ position: 'absolute', top: '8px', right: '8px', cursor: 'pointer', fontSize: '3em' }} onClick={onClose}>
        &times;
      </div>
      <img src={highResImgSrc} alt={`Item ${item.id}`} style={{ 
          maxWidth: '256px', 
          maxHeight: '256px', 
          objectFit: 'contain',
          marginBottom: '8px',
          imageRendering: 'pixelated'
      }} />
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {!loading && !error && (
        <>
          {metadata && metadata.Title && (
            <div key="title" style={{ textAlign: 'center', width: '100%', margin: '0 0' }}>
              <h2 style={{ color: '#fff' }}>{metadata.Title}</h2>
              {contentID && (
                <p style={{ textAlign: 'center', color: '#21a92b' }}>
                  <a href={`https://www.ord.io/${contentID}`} target="_blank" rel="noopener noreferrer" style={{ color: '#21a92b'}}>
                    ORD.IO
                  </a>
                  <span style={{ color: 'white' }}> {"//"} </span>
                  <a href={`https://magiceden.io/ordinals/item-details/${contentID}`} target="_blank" rel="noopener noreferrer" style={{ color: '#21a92b'}}>
                    MagicEden
                  </a>
                  <span style={{ color: 'white' }}> {"//"} </span>
                  <a href={`https://ordinals.com/inscription/${contentID}`} target="_blank" rel="noopener noreferrer" style={{ color: '#21a92b'}}>
                    Ordinals.com
                  </a>
                </p>
              )}
              {idError && <p style={{ color: 'red' }}>Error: {idError}</p>}
            </div>
          )}
          {renderBadges()}
        </>
      )}
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '8px' }}>
        <button onClick={onPrevious} style={{ background: 'transparent', border: 'none', color: '#ffffff', fontSize: '1.5em', cursor: 'pointer' }}>
          &lt;
        </button>
        <button onClick={onNext} style={{ background: 'transparent', border: 'none', color: '#ffffff', fontSize: '1.5em', cursor: 'pointer' }}>
          &gt;
        </button>
      </div>
    </Modal>
  );
};

export default ImageModal;
